@import "../../scss/index.css";
@import "../../scss/mixin.scss";

.aside {
	width: 250px;
	max-height: 100vh;
	overflow-y: scroll;

	--sb-track-color: #ffffff;
	--sb-thumb-color: #ffff;
	--sb-size: 11px;

	&::-webkit-scrollbar {
		width: var(--sb-size);
	}

	&::-webkit-scrollbar-track {
		background: var(--sb-track-color);
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--sb-thumb-color);
		border-radius: 4px;
	}

	@supports not selector(::-webkit-scrollbar) {
		& {
			scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
		}
	}

	.logo_container {
		padding: 10px 5px 10px 18px;
		img {
			width: 50px;
			height: 50px;
		}
		.cursor {
			cursor: pointer;
		}
		svg {
			width: 30px;
			height: 40px;
			color: #0c6c6a;
		}
	}

	.main_menu {
		padding-top: 25px;
		ul {
			list-style: none;
			padding-left: 1rem;
			li {
				a {
					display: flex;
					align-items: center;
					text-decoration: none;
					font-weight: 450;
					padding: 10px 10px;
					margin-bottom: 6px;
					color: #0c6c6a;

					&:hover,
					&.active {
						color: #e1671c;
						background-color: transparent;
					}

					svg {
						font-size: 22px;
					}
					span {
						margin-left: 5px;
						font-size: 17px;
					}
				}
				.nav_link {
					display: flex;
					align-items: center;
					text-decoration: none;
					font-weight: 450;
					padding: 10px 10px;
					margin-bottom: 6px;
					color: #0c6c6a;

					&:hover {
						color: #e1671c;
					}

					svg {
						font-size: 22px;
					}
					span {
						margin-left: 5px;
						font-size: 16px;
					}
					&.dropdown-toggle.show {
						padding-bottom: 0 !important;
					}
				}
				.dropdown_menu {
					display: none;
					border: none;
					position: relative !important;
					width: 100px;
					padding-left: 1rem;
					transform: none !important;
					padding-top: 0;

					&.show {
						display: block !important;
					}
					.dropdown_item {
						background: white;
					}
				}
			}
		}
	}
}

.hamburger-menu {
	display: none;
	position: fixed;
	top: 15px;
	left: 15px;
	z-index: 1000;
	cursor: pointer;
	background-color: #ffffff;
	padding: 5px;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

	svg {
		font-size: 30px;
		color: #0c6c6a;
	}
}

@include break-point(minTab) {
	// .sidebar_section {
	// 	display: none;
	// }

	.aside {
		width: 100%;
	}

	.hamburger-menu {
		display: block;
		padding: 5px;
	}

	.off-canvas-sidebar {
		width: 250px;
		height: 100%;
		background-color: #fff;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		transform: translateX(-100%);
		transition: transform 0.3s ease;
		&.expanded {
			transform: translateX(0);
		}
	}

	.sidebar_section.collapsed {
		width: 100% !important;
		.sidebar-links {
			a {
				span {
					display: inline !important;
				}
			}
			.dropdown span {
				display: inline !important;
			}
		}
		.brandLogo {
			display: block;
		}
		.sidebar_toggler {
			margin-left: 8px !important;
			margin-right: auto !important;
		}
	}
}
