@import "../../scss/index.css";
@import "../../scss/mixin.scss";

.auth_container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 28px 0;

	@include break-point(mobile) {
		padding: 28px 10px;
	}

	@include break-point(minTab) {
		padding-bottom: 28px;
	}

	@include break-point(tablet) {
		padding-bottom: 28px;
	}

	.img_container {
		width: 100%;
		text-align: center;

		img {
			width: 100%;
			max-width: 500px;
			margin: 0 auto;
			height: 480px;
		}
	}

	.right_container {
		display: flex;
		align-items: center;
		justify-content: center;

		.login_form {
			padding-inline: 1rem;
			margin-inline: 30px;
			margin-top: 20px;
			width: 75%;

			.img_container {
				text-align: center;

				img {
					width: 60px;
					height: 60px;
				}
			}
			.form_title {
				padding-bottom: 1.5rem;

				h3 {
					color: #0c6c6a;
					text-align: center;
					padding-top: 50px;
				}

				h5 {
					color: rgb(87, 86, 86);
					text-align: center;
				}
			}

			.form_content {
				.checkbox_wrap {
					// display: flex;
					// justify-content: space-between;
					margin-bottom: 20px;
					text-align: end;

					.checkbox_text {
						color: rgb(87, 86, 86);
					}

					a {
						color: rgb(87, 86, 86);
						text-decoration: none;
						font-size: 15px;
						font-weight: 500;
						line-height: 1.5;
						letter-spacing: 0.00938em;

						@include break-point(mobile) {
							padding-left: 4rem;
						}

						&:hover {
							color: #e1671c;
						}
					}
				}
				.btn_container {
					text-align: center;
					padding-top: 25px;

					.form_button {
						background-color: #0c6c6a !important;
						font-weight: 700;
					}
				}
			}
		}

		.forgot_password {
			margin-inline: 35px;
			margin-top: 30px;
			width: 68%;

			.img_container {
				text-align: center;
				padding-bottom: 3rem;

				img {
					width: 60px;
					height: 60px;
				}
			}
			.form_title {
				text-align: center;
				padding-bottom: 1rem;
				h3 {
					color: #0c6c6a;
				}
				p {
					color: rgb(87, 86, 86);
					font-weight: 500;
				}
			}
			.form_field {
				margin-bottom: 45px;
			}
			.btn_container {
				margin-bottom: 25px;

				.form_button {
					background-color: #0c6c6a !important;
					font-weight: 700;
				}
			}
		}
	}
}
