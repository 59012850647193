@import "../../scss/index.css";
@import "../../scss/mixin.scss";

.toggle-wrapper {
    display: flex;
    align-items: center;

    .switch-text {
        font-size: smaller;
        margin-right: 5px;
    }

    .switch {
        position: relative;
        width: 27px;
        height: 15px;
        margin-left: 10px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;

            &:before {
                position: absolute;
                content: "";
                height: 11px;
                width: 11px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                transition: 0.4s;
            }
        }

        input:checked + .slider {
            background-color: #0c6c6a;

            &:before {
                transform: translateX(13px);
            }
        }

        .round {
            border-radius: 30px;

            &:before {
                border-radius: 50%;
            }
        }
    }
}
