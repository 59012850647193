@import "../../scss/index.css";
/*****  Media Query  *****/
.auth_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px 0;
}
@media screen and (max-width: 575px) {
  .auth_container {
    padding: 28px 10px;
  }
}
@media screen and (max-width: 768px) {
  .auth_container {
    padding-bottom: 28px;
  }
}
@media screen and (max-width: 820px) {
  .auth_container {
    padding-bottom: 28px;
  }
}
.auth_container .img_container {
  width: 100%;
  text-align: center;
}
.auth_container .img_container img {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: 480px;
}
.auth_container .right_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth_container .right_container .login_form {
  padding-inline: 1rem;
  margin-inline: 30px;
  margin-top: 20px;
  width: 75%;
}
.auth_container .right_container .login_form .img_container {
  text-align: center;
}
.auth_container .right_container .login_form .img_container img {
  width: 60px;
  height: 60px;
}
.auth_container .right_container .login_form .form_title {
  padding-bottom: 1.5rem;
}
.auth_container .right_container .login_form .form_title h3 {
  color: #0c6c6a;
  text-align: center;
  padding-top: 50px;
}
.auth_container .right_container .login_form .form_title h5 {
  color: rgb(87, 86, 86);
  text-align: center;
}
.auth_container .right_container .login_form .form_content .checkbox_wrap {
  margin-bottom: 20px;
  text-align: end;
}
.auth_container .right_container .login_form .form_content .checkbox_wrap .checkbox_text {
  color: rgb(87, 86, 86);
}
.auth_container .right_container .login_form .form_content .checkbox_wrap a {
  color: rgb(87, 86, 86);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
@media screen and (max-width: 575px) {
  .auth_container .right_container .login_form .form_content .checkbox_wrap a {
    padding-left: 4rem;
  }
}
.auth_container .right_container .login_form .form_content .checkbox_wrap a:hover {
  color: #e1671c;
}
.auth_container .right_container .login_form .form_content .btn_container {
  text-align: center;
  padding-top: 25px;
}
.auth_container .right_container .login_form .form_content .btn_container .form_button {
  background-color: #0c6c6a !important;
  font-weight: 700;
}
.auth_container .right_container .forgot_password {
  margin-inline: 35px;
  margin-top: 30px;
  width: 68%;
}
.auth_container .right_container .forgot_password .img_container {
  text-align: center;
  padding-bottom: 3rem;
}
.auth_container .right_container .forgot_password .img_container img {
  width: 60px;
  height: 60px;
}
.auth_container .right_container .forgot_password .form_title {
  text-align: center;
  padding-bottom: 1rem;
}
.auth_container .right_container .forgot_password .form_title h3 {
  color: #0c6c6a;
}
.auth_container .right_container .forgot_password .form_title p {
  color: rgb(87, 86, 86);
  font-weight: 500;
}
.auth_container .right_container .forgot_password .form_field {
  margin-bottom: 45px;
}
.auth_container .right_container .forgot_password .btn_container {
  margin-bottom: 25px;
}
.auth_container .right_container .forgot_password .btn_container .form_button {
  background-color: #0c6c6a !important;
  font-weight: 700;
}/*# sourceMappingURL=auth.css.map */