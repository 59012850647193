@import "../../scss/index.css";
@import "../../scss/mixin.scss";

.profile_content {
	width: 80%;
	margin: 0 auto;
	border-radius: 12px;
	background-color: #f7f7f7;

	.title {
		padding: 21px 21px 13px 21px;
		h4 {
			margin-bottom: 0;
			font-weight: 500;
		}
	}
	.content_inner {
		padding: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;

		@include break-point(mobile) {
			padding: 0;
			margin-top: 16px;
		}
		form {
			width: 70%;
			max-width: 500px;

			@include break-point(mobile) {
				width: 85%;
			}

			.form_field {
				position: relative;
				margin-bottom: 1rem;
				.label {
					font-weight: 500;
					font-size: 15px;
				}
				.input {
					padding-right: 2.5rem;
					padding-left: 0.75rem;
				}
				.password_icons {
					position: absolute;
					right: 0.75rem;
					top: 45%;
					cursor: pointer;
					pointer-events: all;
				}
			}
			.button_wrap {
				display: flex;
				justify-content: center;
				gap: 10px;

				@include break-point(mobile) {
					display: block;
					margin-top: 30px !important;
				}
				.buttonSuccess {
					background-color: #0c6c6a;

					@include break-point(mobile) {
						margin-bottom: 6px;
						width: 16rem !important;
					}
					&:hover {
						background-color: #084847;
					}
				}
			}
		}
	}
}
