@import "../../scss/index.css";
/*****  Media Query  *****/
.dashboard_section {
  display: flex;
  justify-content: flex-end;
}
.dashboard_section .sidebar_section {
  width: 100%;
  max-width: 300px;
  padding: 15px;
  position: fixed;
  height: calc(100% - 10px);
  left: 0;
  right: 0;
  overflow: auto;
  background-color: red;
}
.dashboard_section .main_content {
  width: calc(100% - 315px);
}
.dashboard_section .main_content .dashboard_content {
  padding: 20px;
  background-color: #babdc0;
}
@media screen and (max-width: 768px) {
  .dashboard_section .main_content .dashboard_content .dashboard_content {
    width: 100vw;
  }
}

.dashboard_page {
  background-color: #fafafa;
}
.dashboard_page .card_number {
  padding: 5px;
  font-weight: 550;
  font-size: 20px;
  color: #da8149;
}
@media screen and (max-width: 768px) {
  .dashboard_page .card-text {
    display: flex;
    justify-content: center;
  }
}
.dashboard_page .view-link {
  margin: 10px 0 10px 0;
}
.dashboard_page .view-link a {
  text-decoration: none;
  color: #0c6c6a;
}
.dashboard_page .view-link a:hover {
  color: #da8149;
}

@media screen and (max-width: 768px) {
  .page_section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.page_section .center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
  width: 70vw;
  color: #8b8d8d;
}
.page_section .center-container a {
  text-decoration: none;
  color: #000;
}
.page_section .center-container a:hover {
  font-size: 16px;
  font-weight: 550;
  color: #da8149;
}/*# sourceMappingURL=dashboard.css.map */