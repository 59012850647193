@import "../../scss/index.css";
@import "../../scss/mixin.scss";

.form_container {
	margin-top: 3rem;

	.card {
		border: 1px solid #ddd;
		border-radius: 0.25rem;
		background-color: #fafafa;
		border-radius: 12px;
		.card-body {
			padding: 1.5rem 2rem;
			.card-title {
				margin-bottom: 30px;
			}

			.form-group {
				margin-bottom: 1rem;

				&.row {
					display: flex;
					// align-items: center;

					.button-group {
						@include break-point(mobile) {
							text-align: center;
						}
					}

					label {
						text-align: right;
						font-weight: 500;

						@include break-point(mobile) {
							text-align: left;
						}
					}

					.col-form-label {
						padding-right: 0.75rem;
					}

					.form-control {
						width: 75%;
						padding: 0.5rem;
						border: 1px solid #ced4da;
						border-radius: 0.25rem;
						font-size: 14px;

						@include break-point(mobile);
						@include break-point(tablet);
						@include break-point(largeTab) {
							width: 100%;
						}

						@include break-point(landscapeTab) {
							width: 85%;
						}

						@include break-point(minTab) {
							width: 95%;
						}
					}
					.buttonSuccess {
						background-color: #0c6c6a;

						@include break-point(mobile) {
							margin: 0 0 10px 0 !important;
							width: 180px !important;
						}

						&:hover {
							background-color: #084847;
						}
					}

					.btn-secondary {
						@include break-point(mobile) {
							width: 180px !important;
						}
					}
					.fileUploader {
						width: 75%;

						@include break-point(mobile);
						@include break-point(tablet);
						@include break-point(largeTab) {
							width: 100%;
						}

						@include break-point(landscapeTab) {
							width: 85%;
						}

						@include break-point(minTab) {
							width: 95%;
						}
					}

					.fileContainer {
						background: #fff;
						border: 1px solid #ced4da;
						box-shadow: none !important;
						position: relative;
						border-radius: 0.25rem;
						padding: 20px 0;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						margin: 0 !important;
						transition: all 0.3s ease-in;
					}

					.fileContainer input {
						opacity: 0;
						position: absolute;
						z-index: -1;
					}

					.fileContainer p {
						font-size: 12px;
						margin: 8px 0 4px;
					}

					.fileContainer .errorsContainer {
						max-width: 300px;
						font-size: 12px;
						color: red;
						text-align: left;
					}

					.fileContainer .chooseFileButton {
						padding: 6px 23px;
						background: #3f4257;
						border-radius: 30px;
						color: white;
						font-weight: 300;
						font-size: 14px;
						margin: 10px 0;
						transition: all 0.2s ease-in;
						cursor: pointer;
						outline: none;
						border: none;
					}

					.fileContainer .chooseFileButton:hover {
						background: #545972;
					}
				}
			}
		}
	}
}
