@import "../../scss/index.css";
@import "../../scss/mixin.scss";

.header_wrapper {
	z-index: 999;
	position: relative;

	.dashboard_header {
		padding: 15px 0;
		position: sticky;
		width: 100%;
		top: 0;

		.dropdown_custom {
			.dropdown_toggle {
				background-color: transparent;
				display: flex;
				align-items: center;
				border: none;
				color: inherit;

				&:after {
					padding: 0;
					margin: 0;
					border: 0;
					vertical-align: unset;
				}
			}
		}

		.header_container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left_container {
				width: 100%;
				margin-left: 5px;
			}

			h3 {
				margin: 0;

				@include break-point(mobile) {
					display: none;
				}

				@include break-point(minTab) {
					margin: 0 0 0 40px;
				}
			}

			.right_container {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.search_bar {
					width: 100%;
					position: relative;

					@include break-point(mobile) {
						display: none;
					}

					@include break-point(minTab) {
						display: none;
					}

					.search_container {
						position: relative;

						.search_input {
							padding-left: 27px;
							padding-block: 7px;
							border: 1px solid #0c6c6a;
							border-radius: 4px;
							width: 100%;
							transition: border-color 0.3s ease;
							font-size: 15px;

							&:focus {
								outline: 1.5px solid #0c6c6a;
							}
						}

						.search_icon {
							position: absolute;
							top: 50%;
							left: 0.5rem;
							transform: translateY(-50%);
							font-size: 15px;
							color: #525151;
						}
						.search_results {
							position: absolute;
							top: 100%;
							left: 0;
							width: 100%;
							background-color: white;
							border-radius: 10px;
							box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
							border: 1px solid #f2f2f2;
							max-height: 300px;
							overflow-y: auto;
							z-index: 1050 !important;
							--sb-track-color: #ffffff;
							--sb-thumb-color: #ffff;
							--sb-size: 11px;

							&::-webkit-scrollbar {
								width: var(--sb-size);
							}

							&::-webkit-scrollbar-track {
								background: var(--sb-track-color);
								border-radius: 4px;
							}

							&::-webkit-scrollbar-thumb {
								background: var(--sb-thumb-color);
								border-radius: 4px;
							}

							@supports not selector(::-webkit-scrollbar) {
								& {
									scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
								}
							}

							a {
								display: block;
								padding: 5px 5px 5px 8px;
								text-align: left;
								text-decoration: none;
							}
						}
					}
				}

				.dropdown_notification {
					.dropdown_custom {
						margin: 0 15px;

						.dropdown-toggle {
							font-weight: 450;
							position: relative;
							background-color: #0c6c6a;
							border-color: #0c6c6a;

							@include break-point(minTab) {
								&::after {
									color: #0c6c6a;
									margin-inline-start: -7px;
								}
								span {
									display: none !important;
								}
							}

							svg {
								font-size: 25px;
								padding: 3px;
								// font-weight: 600;
							}
						}
					}
				}

				.dropdown_profile {
					.dropdown_custom {
						.dropdown-toggle {
							position: relative;
							background-color: #0c6c6a;
							border-color: #0c6c6a;
							font-weight: 450;

							@include break-point(minTab) {
								&::after {
									color: #0c6c6a;
									margin-inline-start: -7px;
								}
								span {
									display: none !important;
								}
							}

							svg {
								font-size: 25px;
								padding: 3px;
								margin-bottom: 3px;
							}
						}

						.dropdown-menu {
							z-index: 1050 !important;
							position: absolute;
							width: 200px;
							border-radius: 10px;
							box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
							border: 1px solid #f2f2f2;
							padding-top: 10px;
							padding-bottom: 10px;

							.dropdown-item {
								padding: 10px 10px;
								a {
									width: 100%;
									color: #0c6c6a;
									text-decoration: none;
									font-weight: 450;
									&:hover {
										color: #e1671c;
									}
									svg {
										font-size: 22px;
										margin-right: 3px;
									}
								}

								&.button_logout {
									border-top: 1px solid #f1f1f1;
									padding-bottom: 0;
									padding-top: 10px;
									margin-top: 5px;
									span {
										color: #0c6c6a;
										font-weight: 450;
										cursor: pointer;
										&:hover {
											color: #e1671c !important;
										}
										svg {
											font-size: 22px;
											margin-right: 3px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
