@import "../../scss/index.css";

.btnPrimary {
	background-color: #0c6c6a;
	border-color: #0c6c6a;
	&:hover {
		background-color: #084847 !important;
	}
	&:active {
		background-color: #084847 !important;
	}
	&.loading {
		background-color: #084847 !important;
		border-color: #084847 !important;
	}
}

.pagination {
	.page-link {
		background-color: #cfd4d4;
		color: rgb(0, 0, 0);
		border-color: white;

		&:focus {
			box-shadow: none;
		}
	}
	.page-item.active {
		.page-link {
			background-color: #0c6c6a;
			color: #fff;
		}
	}
}
