@import "../../../scss/index.css";

.reset_section {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;

	.form_container {
		padding-inline: 1rem;
		margin-inline: 30px;
		margin-block: 40px;
		background-color: transparent !important;

		.logo {
			text-align: center;

			img {
				width: 60px;
				height: 60px;
			}
		}
		.form_heading {
			padding-bottom: 1.5rem;

			h3 {
				color: #0c6c6a;
				text-align: center;
				padding-top: 40px;
			}

			h6 {
				color: rgb(87, 86, 86);
				text-align: center;
			}
		}

		.form_content {
			.btn_container {
				text-align: center;
				padding-top: 20px;

				.form_button {
					background-color: #0c6c6a !important;
					font-weight: 700;
				}
			}
		}
	}
}
